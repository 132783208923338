import events from 'events';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// import { Calendar } from 'react-calendar';
import Calendar from 'react-calendar'
import { images } from '../../assets/images';
import { eventService } from '../../services/events.service';
import { DynamicLoader } from '../../utils/DynamicLoader';
import { Toast } from '../../utils/Toast';
import { IEvent } from '../organisations/createorganisation/interfaces/event.interface';
import { EventDetailModal } from '../ownorganisation/components/EventDetailModal';
import { EventDisplayToModal } from './EventDisplayToModal';
import { ShowEventModal } from './ShowEventModal';
import {useTranslation} from "react-i18next";
import {useEventsData} from "../../redux/cache-slice/useEventsData";

export const ThemeCalendar = () => {
  const [currentDate, setCurrentDate] = useState(new Date());
  const [events, setEvents] = useState<IEvent[]>([]);
  const [eventCounts,setEventCounts] = useState<any[]>([])
  const [selectedEvent, setSelectedEvent] = useState<IEvent>();
  const [showDetailModal, setShowDetailModal] = useState<boolean>(false);
  const [showMemberDetailModal, setShowMemberDetailModal] = useState<boolean>(false);
  const [isLoading, setIsloading] = useState(false);
  const [showDisplayToModal, setShowDisplayToModal] = useState(false);
  const { t } = useTranslation('', { keyPrefix: 'ThemeCalendar' });
  const [refresh, setRefresh] = useState(false);

  const {eventsCounts, secondaryData, lastFetched, loadingData} = useEventsData(refresh)
  const format2 = "YYYY-MM-DD HH:mm"
  const timeFormat = "HH-mm"
  let timeoutId: any;

  useEffect(() => {
    setCurrentDate(new Date())
    clearTimeout(timeoutId);

    timeoutId = setTimeout(() => {
      if (eventsCounts){
        setEventCounts(eventsCounts)
      }
      if (secondaryData){
        setEvents(secondaryData as IEvent[])
      }
    }, 2000); // Set delay as needed
  }, [eventsCounts,secondaryData])

  const onDateChange = (event: any) => {
    setCurrentDate(event);
    if (!moment(event).isSame(currentDate, 'month'))
    {
      getEventCounts(event);
    }
    getEvents(event);
  }
  const getEvents = async (date: Date) => {
    setIsloading(true);
    let res: any = await eventService.getDateEvents(`${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`);
    if (res.statusCode == 200) {
      setEvents([...res.payload.events.sort((a:any,b:any) => {
        const date1 :any= new Date(a.startDate);
        const date2 :any= new Date(b.startDate);
        return date1 - date2;
      })])
      setIsloading(false)
    } else {
      Toast.fire({
        icon: 'warning',
        title: res.message
      })
      setIsloading(false)
    }
  }
  const getEventCounts = async (startDate?:any) => {
    setIsloading(true);
    const startOfMonth =  (startDate ? moment(startDate) : moment()).clone().startOf('month').subtract(7, 'days').format('MM-DD-YYYY');
    const endOfMonth = (startDate ? moment(startDate) : moment()).clone().endOf('month').add(7, 'days').format('MM-DD-YYYY');

    let res: any = await eventService.getEventCounts(startOfMonth,endOfMonth);
    if (res.statusCode == 200) {
      for (let eventsCount of res.payload.eventsCounts) {
        eventsCount.date =  eventsCount._id.month + '-' + eventsCount._id.day + '-' + eventsCount._id.year
      }
      startDate && setCurrentDate(startDate)
      setEventCounts(res.payload.eventsCounts)
      getEvents(startDate ?? currentDate);
    } else {
      Toast.fire({
        icon: 'warning',
        title: res.message
      })
      setIsloading(false)
    }
  }

  const openDetailModal = (eve: IEvent) => {
    setSelectedEvent(eve)
    setShowDetailModal(true)
  }
  const openMemberDetailModal = (eve: IEvent) => {
    setSelectedEvent(eve)
    setShowMemberDetailModal(true)
  }

  const closeDetailModal = () => {
    setShowDetailModal(false)
  }
  const closeEventDetailModal = () => {
    setShowMemberDetailModal(false)
  }
  const closeEventDisplayToModal = (data: any) => {
    setShowDisplayToModal(false)
  }
  const show = (props:any) => {
    if (props.view === "month") {
      for (let eventCount of eventCounts) {
        if (moment(eventCount.date).isSame(moment(props.date)))
        {
          return <div>{eventCount.count}</div>;
        }
      }
    }
    return null;
  };

  const onViewChange = (props:any) => {
    if (props.view==='month')
    {
      getEventCounts(props.activeStartDate)
    }
  }

  const tileClassName = (data: any): any => {
    if (data.view === "month") {
      for (let eventCount of eventCounts) {
        if (moment(eventCount.date).isSame(moment(data.date)))
        {
          return 'up-btn-event';
        }
      }
    }
  }
  return (
    <div>
      {(isLoading || loadingData) && <DynamicLoader />}
      <Calendar
        onChange={onDateChange}
        value={currentDate}
        tileContent={show}
        tileClassName={tileClassName}
        onViewChange={onViewChange}
      />
      <div className="up-calendareventsarea">
        <ul className="up-calendarevents">
          {events && events.length > 0 && events.map((event: IEvent) => (<li>
            <div key={event._id} onClick={(e) => {
              e.preventDefault();
              openDetailModal(event)
            }} className="up-calendarevent">
              <figure className="up-eventimage">
                <img src={event?.avatar ?? images.RestPlaceholder} alt="Event Image" />
              </figure>
              <div className="up-eventcontent">
                <h4>{event.name}</h4>
                <span>{event.location}</span>
                <span>{event.organization.name}</span>
                {moment(new Date(event.startDate)).isSame(new Date(event.endDate), 'day') ? <time dateTime="">{moment(event.startDate).format(format2)} {' - '} {moment(event.endDate).format(timeFormat)}</time> : <time dateTime="">{moment(event.startDate).format(format2)} {' - '} {moment(event.endDate).format(format2)}</time>}
                <span onClick={(e) => {
                  e.stopPropagation();
                  openMemberDetailModal(event)
                }}>{t('confirmed')} {event.joinedMembers.length}</span>
                <span onClick={(e) => {
                  e.stopPropagation();
                  openMemberDetailModal(event)
                }}>{t('maybe')} {event.interestedMembers.length}</span>
                {moment().isBefore(moment(new Date(event.joiningEndDate))) && <span>{moment(new Date(event.joiningEndDate)).fromNow()}</span>}
              </div>
            </div>
          </li>))}
          {events && events.length === 0 && <div>{t('noUpcomingEvents')}</div>}
          {showDetailModal && <ShowEventModal editEvent={selectedEvent!} closeModal={closeDetailModal} />}
          {showMemberDetailModal && <EventDetailModal editEvent={selectedEvent!} closeModal={closeEventDetailModal} />}
          {showDisplayToModal && <EventDisplayToModal editEvent={selectedEvent!} closeModal={closeEventDisplayToModal} />}
        </ul>
      </div>
    </div>
  );
}

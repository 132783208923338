import { IResponseInterface } from "../interfaces/api-response.interface";
import { HttpService } from "./base.service";
import { ICreateOrganisation } from "../features/organisations/createorganisation/interfaces/create-organisation.interface";
import { IOrganisationTypes } from "../features/organisations/createorganisation/interfaces/organisation-types.interface";
import { IOrganisationSetup } from "../features/organisations/createorganisation/interfaces/organisation-setup.interface";
import { IChildOrganisationStatusUpdate, IFollowOrganisationRequestStatus, IFollowOrganizationRequestStatusIds } from "../features/organisations/createorganisation/interfaces/child-organisation-status-update.interface";
import { IFollowOrganizationRequest, IOrganization, IOrganizationBannerAvatar } from "../features/organisations/createorganisation/interfaces/organisation.interface";
import { IOrganizationRequestedMembers, IOrganizationRole } from "../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import { IAddParentOrganization } from "../features/organisations/createorganisation/interfaces/add-parent-organization.interface";
class OrganisationService extends HttpService {
  private readonly prefix: string = "organization";
  private readonly prefixOrganizationMember: string = "organization-member";
  private readonly prefixOrganizationRole: string = "organization-role";

  getOrganisationTypes = (): Promise<IResponseInterface<{organizationTypes: IOrganisationTypes[]}>> => this.get(`${this.prefix}-type`)

  createOrganization = (data: ICreateOrganisation): Promise<IResponseInterface<{organization: IOrganization}>> => this.post(`${this.prefix}`, data);
 
  organizationProfileSetup = (data: IOrganisationSetup, id: string): Promise<IResponseInterface<{organization: IOrganization}>> => this.put(`${this.prefix}/setup/${id}`, data);
 
  searchParentOrganization = (data: string, page: number, country?: string, city?: string): Promise<IResponseInterface<{organization: IOrganization[]}>> => this.get(`${this.prefix}/search?query=${data}&page=${page}&limit=20${country? '&country=' + country : ''}${city? '&city=' + city : ''}`);

  followingOrganizations = (data: string, page: number, country?: string, city?: string): Promise<IResponseInterface<{organization: IOrganization[]}>> => this.get(`${this.prefix}/follow-creator?query=${data}&page=${page}&limit=20${country? '&country=' + country : ''}${city? '&city=' + city : ''}`);

  searchUserOrganization = (data: string, page: number): Promise<IResponseInterface<{organization: IOrganization[]}>> => this.get(`${this.prefix}/follow-creator?query=${data}&page=${page}&limit=20`);

  getUserOrganizations = (userId: string): Promise<IResponseInterface<{organization: IOrganization[]}>> => this.get(`${this.prefix}/user/${userId}`);

  getUserOrganizationComment = (userId: string): Promise<IResponseInterface<{organization: IOrganization[]}>> => this.get(`${this.prefixOrganizationMember}/detail/${userId}`);

  getRequestedOrganizations = (id: string): Promise<IResponseInterface<{organizations: IOrganisationTypes[]}>> => this.get(`${this.prefix}/requested-organizations/${id}`)

  childOrganizationStatusUpdateRequest = (data: IChildOrganisationStatusUpdate): Promise<IResponseInterface<{}>> => this.put(`${this.prefix}/update-request`, data);

  getOrganizationById = (id: string): Promise<IResponseInterface<{organization: IOrganization}>> => this.get(`${this.prefix}/${id}`);

  getPublicOrganizationById = (id: string): Promise<IResponseInterface<{organization: IOrganization}>> => this.get(`public/${id}`);

  getOrganizationRequestedMembers = (id: string,page: number): Promise<IResponseInterface<{organizationMembers: IOrganizationRequestedMembers}>> => this.get(`${this.prefixOrganizationMember}/pending-follow-requests/${id}?page=${page}&limit=20`);

  followOrganizationRequest = (data: IFollowOrganizationRequest): Promise<IResponseInterface<{}>> => this.post(`${this.prefixOrganizationMember}/send-follow-request`, data);

  updateOrganizationFollowStatus = (data: IFollowOrganisationRequestStatus,id:string): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationMember}/update-follow-request/${id}`, data);
 
  updateOrganizationFollowStatusIds = (data: IFollowOrganizationRequestStatusIds): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationMember}/update-follow-request`, data);

  unfollowOrganization = (data: any): Promise<IResponseInterface<{}>> => this.post(`${this.prefixOrganizationMember}/un-follow`, data);

  getOrganizationMembers = (id: string,page: number, query: string, limit: number, type?: string): Promise<IResponseInterface<{organizationMembers: IOrganizationRequestedMembers}>> => this.get(`${this.prefixOrganizationMember}/${id}?page=${page}&limit=${limit}&query=${query}${type ? '&type=' +type:''}`);

  removeOrganizationMembers = (id: string): Promise<IResponseInterface<{}>> => this.delete(`${this.prefixOrganizationMember}/remove/${id}`);

  addParentOrganization = (data: IAddParentOrganization): Promise<IResponseInterface<{}>> => this.put(`${this.prefix}/send/parent-request`, data);

  getMyOrganizationRole = (id: string): Promise<IResponseInterface<{organizationMember:IOrganizationRequestedMembers}>> => this.get(`${this.prefixOrganizationMember}/my-role/${id}`);

  updateOrganizationBannerAndAvatar = (data: IOrganizationBannerAvatar, id: string): Promise<IResponseInterface<{}>> => this.put(`${this.prefix}/update/banner-avatar/${id}`, data);

  getOrganizationRoles = (id: string): Promise<IResponseInterface<{organizationRoles: IOrganizationRole[]}>> => this.get(`${this.prefixOrganizationRole}/organization/${id}`);
 
  getOrganizationMedia = (id: string, page:number,type:string): Promise<IResponseInterface<{}>> => this.get(`${this.prefix}/${id}/media?type=${type}&page=${page}&limit=10`);

  createOrganizationRole = (data:any): Promise<IResponseInterface<{}>> => this.post(`${this.prefixOrganizationRole}/create`,data);

  updateOrganizationRole = (data: any,roleId:string): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationRole}/update/${roleId}`, data);

  deleteOrganizationRole = (id: string): Promise<IResponseInterface<{}>> => this.delete(`${this.prefixOrganizationRole}/${id}`);

  updateOrganizationMemberRole = (data: any): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationMember}/update-role`, data);
  updateOrganizationMemberValidity = (data: any): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationMember}/update-validity`, data);

  addOrganizationInviteCodes = (data:any): Promise<IResponseInterface<{}>> => this.post('member-code/import',data);

  getOrganizationInviteCodes = (id: string, page:number): Promise<IResponseInterface<{}>> => this.get(`member-code/organization/${id}?page=${page}&limit=20`);

  getOrganizationCodeLogs = (id: string, code:string): Promise<IResponseInterface<{}>> => this.get(`member-code/organization/log/${id}/${code}`);

  getOrganizationCsvFiles = (id: string): Promise<IResponseInterface<{}>> => this.get(`member-code/organization/imported-files/${id}`);

  deleteOrganizationCsvFile = (data: any): Promise<IResponseInterface<{}>> => this.delete(`member-code/csv`,'',  data);

  eventsBulkUpload = (data:any): Promise<IResponseInterface<{}>> => this.post('event/bulk-upload',data);

  getOrganizationFields = (id: string): Promise<IResponseInterface<{}>> => this.get(`organization-field/organization/${id}`);

  createOrganizationFields = (data: any): Promise<IResponseInterface<{}>> => this.post(`organization-field`,data);

  updateOrganizationFields = (id:string,data: any): Promise<IResponseInterface<{}>> => this.put(`organization-field/${id}`,data);

  deleteOrganizationUserField = (id: string): Promise<IResponseInterface<{}>> => this.delete(`organization-field/${id}`);

  getOrganizationFieldValue = (id: string): Promise<IResponseInterface<{}>> => this.get(`organization-field-value/${id}`);

  createUpdateOrganizationFieldValue = (data: any): Promise<IResponseInterface<{}>> => this.post(`organization-field-value`,data);

  setDefaultRole = (data: any): Promise<IResponseInterface<{}>> => this.post(`organization-role/mark-as-default`,data);

  getOrgUserGroupsNames = (id: string): Promise<IResponseInterface<{}>> => this.get(`group/name/${id}`);

  makeOrganizationUserPublic = (id: string): Promise<IResponseInterface<{}>> => this.put(`${this.prefixOrganizationMember}/make-public/${id}`);
}
export const organisationService = new OrganisationService();

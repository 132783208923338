import {User} from "../user/User";
import {DashboardLayout} from "../layouts/DashboardLayout";
import {useLocation, useParams} from "react-router";
import {IIdFromUrl} from "../../interfaces/id-from-url.interface";
import UsersIcon from "../../assets/images/d-icon/users.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useEffect, useRef, useState} from "react";
import {
    IOrganizationRequestedMembers
} from "../../features/organisations/createorganisation/interfaces/organization-requested-members.interface";
import {dashboardService} from "../../services/dashboard.service";
import {FullPageLoader} from "../../utils/FullPageLoader";
import {Toast} from "../../utils/Toast";
import {IPost} from "../../features/organisations/createorganisation/interfaces/post.interface";
import {OrganizationPost} from "../Post/Post";
import {useSelector} from "react-redux";
import {getUser} from "../../features/auth/slice/user.selector";
import {IOrganization} from "../../features/organisations/createorganisation/interfaces/organisation.interface";
import {organisationService} from "../../services/organisation.service";
import {IGroup} from "../../features/organisations/createorganisation/interfaces/group.interface";
import {groupService} from "../../services/group.service";
import Swal from "sweetalert2";
import {postService} from "../../services/post.service";
import {CreatePostModall} from "../../features/ownorganisation/components/CreatePostModal";
import {ReportPostModal} from "../../features/ownorganisation/components/ReportPostModal";
import {SharePostOrganizationModal} from "../../features/ownorganisation/components/SharePostModal";
import {SharePostInUserGroupModal} from "../../features/ownorganisation/components/SharePostInUserGroupsModal";
import AsyncSelect from "react-select/async";
import NoDataFound from "../NoData/NoDataFound";
import {IPaginationInfo} from "../../interfaces/pagination-info.interface";
import {CSVLink} from "react-csv";
import InfiniteScroll from "react-infinite-scroll-component";
import {useTranslation} from "react-i18next";


export default function Updates() {
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;
    const [isLoading, setIsloading] = useState(true);
    const [topAdmins, setTopAdmins] = useState<IOrganizationRequestedMembers[]>([]);
    const [organizationPosts, setOrganizationPosts] = useState<IPost[]>([]);
    const [csvPosts, setCSVPosts] = useState<any[]>([]);
    const [selectedEditPost, setSelectedEditPost] = useState<IPost>();
    const [showReportPostModal, setShowReportPostModal] = useState(false)
    const [isEditPost, setIsEditPost] = useState(false);
    // const [postCount, setPostCount] = useState(0);
    const [selectedGroup, setSelectedGroup] = useState<string>();
    const [selectedUser, setSelectedUser] = useState<string>();
    const [viewCount, setViewCount] = useState(0);
    const [createPostModal, setCreatePostModal] = useState(false);
    const [postToBeShared, setPostToBeShared] = useState<IPost>();
    const [userOrganizations, setUserOrganization] = useState<IOrganization[]>();
    const [sharePostModal, setSharePostModal] = useState(false);
    const [postToBeSharedInOrganization, setPostToBeSharedInOrganization] = useState<IPost>();
    const [userGroups, setUserGroups] = useState<IGroup[]>([]);
    const [sharePostInUserGroupsModal, setSharePostInUserGroupsModal] = useState(false);
    const [paginationInfoPost, setPaginationInfoPost] = useState<IPaginationInfo>();

    const currentUser = useSelector(getUser);
    const csvLink: any = useRef()
    let { id } = useParams<IIdFromUrl>();
    const location = useLocation();
    const [organizationName, setOrganizationName] = useState(location?.search.replace('?',''));
    const { t } = useTranslation('', { keyPrefix: 'UserData' });

    useEffect(() => {
        allCalls()
    }, [])

    useEffect(() => {
        if (csvPosts && csvPosts.length > 0) {
            csvLink?.current?.link?.click();
            setCSVPosts([]);
        }
    }, [csvPosts])

    useEffect(() => {
        getDashboardPosts(1,10,false)
    }, [selectedGroup, selectedUser, dateRange])

    const allCalls = async () => {
        setIsloading(true)
        await Promise.all([getDashboardViewCount(), getDashboardPosts(1, 10), getDashboardMemberTop(id, 1)])
        setIsloading(false)
    }

    const getDashboardMemberTop = async (orgId: string, page: number) => {
        const res: any = await dashboardService.getDashboardTopAdmins(orgId, page, ' ');
        if (res.statusCode == 200) {
            setTopAdmins(res.payload.topOrganizationMembers.records)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const getDashboardPosts = async (page: number, limit: number, downloadCSV = false) => {
        setIsloading(true)
        let params = `?page=${page}&limit=${limit}`;
        setIsloading(true)
        if (selectedGroup) {
            params = params + `&group=${selectedGroup}`;
        }
        if (selectedUser) {
            params = params + `&creator=${selectedUser}`;
        }
        if (dateRange[0]) {
            let startDate: any = dateRange[0];
            let endDate: any = dateRange[1];
            startDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`
            if (endDate)
            {
                endDate = `${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`
            }
            params = params + `&startDate=${startDate}&endDate=${endDate || startDate}`;
        }
        const res: any = await dashboardService.getDashboardPosts(id,params);
        setIsloading(false)
        if (res.statusCode == 200) {
            if (downloadCSV) {
                const csvData = [['Post Creator Name', 'Post Creator Username', 'Post Content', 'Post Organization Name', 'Likes', 'Comments count', 'Allow Comment', 'Allow Share']];
                for (const iterator of res.payload.posts.records) {
                    let item = [iterator?.creator?.name, iterator?.creator?.username, iterator?.content, iterator?.organization?.name, iterator?.likes?.length || 0, iterator?.commentsCount || 0, iterator?.allowComment, iterator?.allowShare];
                    csvData.push(item);
                }
                setCSVPosts(csvData)
            } else {
                if (page ==1)
                {
                    setOrganizationPosts(res.payload.posts.records)
                }else{
                    setOrganizationPosts([...organizationPosts,...res.payload.posts.records])
                }
                setPaginationInfoPost(res.payload.posts.paginationInfo);
            }
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    // const getDashboardPostCount = async () => {
    //     let params = '';
    //     setIsloading(true)
    //     if (selectedGroup) {
    //         params = `?group=${selectedGroup}`;
    //     }
    //     if (selectedUser) {
    //         params = params + `${params.length > 0 ? '&' : '?'}user=${selectedUser}`;
    //     }
    //     if (dateRange[0] && dateRange[1]) {
    //         let startDate: any = dateRange[0];
    //         let endDate: any = dateRange[1];
    //         startDate = `${startDate.getMonth() + 1}-${startDate.getDate()}-${startDate.getFullYear()}`
    //         endDate = `${endDate.getMonth() + 1}-${endDate.getDate()}-${endDate.getFullYear()}`
    //         params = params + `${params.length > 0 ? '&' : '?'}firstDate=${startDate}&lastDate=${endDate}`;
    //     }
    //     const res: any = await dashboardService.getDashboardPostCount(id, params);
    //     setIsloading(false)
    //     debugger;
    //     if (res.statusCode == 200) {
    //         setPostCount(res.payload.postCount)
    //     } else {
    //         Toast.fire({
    //             icon: 'warning',
    //             title: res.message
    //         })
    //     }
    // }
    const getDashboardViewCount = async () => {
        const res: any = await dashboardService.getDashboardViewCount(id);
        if (res.statusCode == 200) {
            setViewCount(res.payload.avgNumOfViews)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const setLikePost = (postId: string, userId: string, status: string) => {

        const postIndex = organizationPosts?.findIndex((post) => post._id === postId);
        if (postIndex !== -1) {
            if (status === 'YES') {
                organizationPosts[postIndex].likes.push(currentUser._id);
            } else {
                const index = organizationPosts[postIndex].likes.findIndex((lik) => lik === userId);
                if (index !== -1) {
                    organizationPosts[postIndex].likes.splice(index, 1);
                }
            }
            setOrganizationPosts([...organizationPosts])
        }
    }
    const reportPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setShowReportPostModal(true);
    }
    const editPost = (selectedPost: IPost) => {
        setSelectedEditPost(selectedPost);
        setIsEditPost(true);
        setCreatePostModal(true);
    }
    const sharePost = async (selectedPost: IPost) => {
        setPostToBeShared(selectedPost);
        const res: any = await organisationService.getUserOrganizations(currentUser._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select Organisation.' }, ...res.payload.organizations]
            setUserOrganization(temp)
            setSharePostModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const shareInUserGroupsPost = async (selectedPost: IPost) => {
        setPostToBeSharedInOrganization(selectedPost);
        const res: any = await groupService.getUserGroups(1, '', selectedPost.organization?._id)
        if (res.statusCode === 200) {
            const temp = [{ _id: 0, name: 'Select User Group' }, ...res.payload.groups.records]
            setUserGroups(temp)
            setSharePostInUserGroupsModal(true);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const deletePost = async (selectedPost: IPost) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await postService.deletePost(selectedPost._id);
                    if (res.statusCode = 200) {
                        const tempIndex = organizationPosts!.findIndex((post: any) => post._id === selectedPost._id)
                        organizationPosts!.splice(tempIndex, 1);
                        setOrganizationPosts([...organizationPosts!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }
    const handleClosePostModal = () => {
        setCreatePostModal(false);
        setIsEditPost(false);
        setSelectedEditPost(undefined)
    }
    const setPosts = (data: IPost[]) => {
        setOrganizationPosts(data);
        setCreatePostModal(false);
    }
    const closeReportPostModal = () => {
        setShowReportPostModal(false);
    }
    const closeSharePostModal = (data: boolean) => {
        setSharePostModal(false);
        setSharePostInUserGroupsModal(false);
    }
    const onChangeSelectedOptionGroups = (e: any) => {
        setSelectedGroup(e?.value ?? null);
    };
    const onChangeSelectedOptionUsers = (e: any) => {
        setSelectedUser(e?.value ?? null);
    };
    const promiseOptionsGroups = async (inputValue: string) => {
        // if (inputValue.length > 1) {
            const res: any = await groupService.getOrganizationGroups(id, 1, inputValue, 10);
            if (res.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.groups.records.length; i++) {
                    tempArray.push({
                        label: res.payload.groups.records[i].name,
                        value: res.payload.groups.records[i]._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        // }
    }
    const promiseOptionsUsers = async (inputValue: string) => {
        if (inputValue.length > 2) {
            const res: any = await organisationService.getOrganizationMembers(id, 1, inputValue, 10);
            if (res.statusCode == 200) {
                const tempArray: any[] = [];
                for (let i = 0; i < res.payload.organizationMembers.records.length; i++) {
                    tempArray.push({
                        label: res.payload.organizationMembers.records[i].member.name,
                        value: res.payload.organizationMembers.records[i].member._id
                    })
                }
                return tempArray;
            } else {
                Toast.fire({
                    icon: 'warning',
                    title: res.message
                })
            }
        }
    }
    return (
        <DashboardLayout id={id} orgName={organizationName}>
            {isLoading && <FullPageLoader />}
            <div className="up-eventspageholder">
                <div className="up-userfilterholder">
                    <div className="up-userspagetitle">
                        <h2>{t('updates')}</h2>
                    </div>
                    <form className="up-formtheme">
                        <fieldset>

                            <CSVLink
                                data={csvPosts}
                                filename='posts.csv'
                                className='hidden'
                                ref={csvLink}
                                target='_blank'
                            />
                            <button type="button" className="up-btn" onClick={() => getDashboardPosts(1, paginationInfoPost?.totalRecords!, true)}>{t('downloadCSV')}</button>
                            {/* <div className="form-group">
                                <div className="up-inputwithicon">
                                    <input type="text" name="search" placeholder="Search artists" />
                                    <i className="icon-search"></i>
                                </div>
                            </div>
                            <div className="up-filterbtn">
                            </div> */}
                            {/* <button className="up-btnfilter">
                                    <i className="icon-filter"></i>
                                    <span>Filter</span>
                                </button> */}
                            {/* <DateRangePicker
                                    initialSettings={{ startDate: '1/1/2014', endDate: '3/1/2014' }}
                                >
                                    <button>Click Me To Open Picker!</button>
                                </DateRangePicker> */}
                            {/* </div> */}
                        </fieldset>
                    </form>
                </div>
                <div className="up-eventtwocol">
                    <div className="up-eventcolone">
                        {organizationPosts && <InfiniteScroll
                            dataLength={organizationPosts!.length} //This is important field to render the next data
                            next={() => getDashboardPosts(paginationInfoPost?.currentPage! + 1, 10)}
                            hasMore={organizationPosts!.length !== paginationInfoPost?.totalRecords!}
                            loader={<h4>{t('loading')}</h4>}
                            endMessage={<></>}>
                            {organizationPosts && organizationPosts.map((post, index) => {
                                return <OrganizationPost fromAdmin={true} public={false} fromDashboard={false} setLikePost={setLikePost} reportPost={reportPost} fromTimeline={true} key={post._id + index} post={post} showInOrganization={true} setEditPost={editPost} setShareOrganizationPost={sharePost} setShareInUserGroupsPost={shareInUserGroupsPost} setDeletePost={deletePost} />
                            })}
                        </InfiniteScroll>}
                        {organizationPosts && organizationPosts.length === 0 && <NoDataFound />}
                        {createPostModal && <CreatePostModall id={selectedEditPost?.organization?._id!} organizationPosts={organizationPosts!} selectedEditPost={selectedEditPost!} isEditPost={isEditPost} setOrganizationPosts={setPosts} closeModal={handleClosePostModal} organizationRoles={[]} />}
                        {showReportPostModal && <ReportPostModal closeModal={closeReportPostModal} />}
                        {sharePostModal && <SharePostOrganizationModal postToBeShared={postToBeShared!} userOrganizations={userOrganizations!} closeModal={closeSharePostModal} />}
                        {sharePostInUserGroupsModal && <SharePostInUserGroupModal postToBeShared={postToBeSharedInOrganization!} userGroups={userGroups!} closeModal={closeSharePostModal} />}
                    </div>
                    <div className="up-eventcoltwo up-postudatestopevents">
                        <div className='up-statsgrid mt-0 '>
                            <div className="up-stat">
                                <form className="up-formtheme">
                                    <fieldset>
                                        <div className="up-inputwidthfifty up-inputresponsive">
                                            <div className="form-group">
                                                <div className="up-inputwithicon">
                                                    <AsyncSelect 
                                                    defaultValue={selectedGroup} 
                                                    isClearable={true} 
                                                    // menuIsOpen={true}
                                                    className="up-async-select up-mainsearchholder up-eventpageselectissue"
                                                     placeholder={t('searchUserGroups')}
                                                     onChange={onChangeSelectedOptionGroups}
                                                      cacheOptions 
                                                      defaultOptions 
                                                      loadOptions={promiseOptionsGroups} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="up-inputwithicon">
                                                    <AsyncSelect defaultValue={selectedUser} isClearable={true} className="up-async-select up-mainsearchholder up-eventpageselectissue" placeholder={t('searchUsers')} onChange={onChangeSelectedOptionUsers} cacheOptions defaultOptions loadOptions={promiseOptionsUsers} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="up-filterbtn up-datapickerfilterarea">

                                            <DatePicker
                                                selectsRange={true}
                                                startDate={startDate}
                                                endDate={endDate}
                                                onChange={(update: any) => {
                                                    setDateRange(update);
                                                }}
                                                placeholderText={t('selectDateRange')}
                                                isClearable={true}
                                            />
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>{t('updates')}</span>
                                            <h3>{paginationInfoPost?.totalRecords || 0}</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="up-stat">
                                <span className="up-staticon">
                                    <img src={UsersIcon} alt='statsicon' />
                                </span>
                                <div className="up-statcontent">
                                    <ul>
                                        <li>
                                            <span>{t('averageViews')}</span>
                                            <h3>{viewCount || 0}</h3>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {/* <div className="up-dashboarddata">
                            <div className="up-dashboardsectiontitle">
                                <h3>Top 10 Popular Events</h3>
                                <a href="javascript: void(0);">View All</a>
                            </div>
                            <ul>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                                <li>
                                    <User />
                                </li>
                            </ul>
                        </div> */}
                        <div className="up-dashboarddata mb-4">
                            <div className="up-dashboardsectiontitle">
                                <h3>{t('topJournalists')}</h3>
                            </div>
                            <ul style={{ height: '200px' }}>
                                <li>
                                    <User data={topAdmins} />
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </DashboardLayout>
    )
}
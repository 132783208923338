import {createSlice} from "@reduxjs/toolkit";
import {FetchCurrentDayEvents, FetchEventCounts, FetchPixeeImages} from "./cache.thunk";
import {ICacheState} from "./cache.interface";

const initialState: ICacheState = {
    currentDayEvents: null,
    eventCountData: null,
    eventsLastFetched: null,
    imagesData: null,
    imagesPagination: null,
    imagesLastFetched: null,
    loadingData:false,
    error: null,
    status: 'idle'
};

const cacheReducer = createSlice({
    name: "cacheReducer",
    initialState,
    reducers: {
        resetData(state) {
            state.imagesData = null;
            state.imagesPagination = null;
            state.imagesLastFetched = null;
            state.eventCountData = null;
            state.currentDayEvents = null;
            state.eventsLastFetched = null;
            state.loadingData = false;
            state.error = null;
        },
    },
    extraReducers: {
        [FetchPixeeImages.pending.toString()]: (state) => {
            state.status = 'loading';
            state.loadingData = true;
        },
        [FetchPixeeImages.fulfilled.toString()]: (state, action) => {
            state.status = 'succeeded';
            state.imagesData = action.payload.records;
            state.imagesPagination = action.payload.paginationInfo;
            state.imagesLastFetched = Date.now();
            state.loadingData = false;
        },
        [FetchPixeeImages.rejected.toString()]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.loadingData = false;
        },
        [FetchEventCounts.pending.toString()]: (state) => {
            state.status = 'loading';
            state.loadingData = true;
        },
        [FetchEventCounts.fulfilled.toString()]: (state, action) => {
            state.status = 'succeeded';
            for (let eventsCount of action.payload.eventsCounts) {
                eventsCount.date =  eventsCount._id.month + '-' + eventsCount._id.day + '-' + eventsCount._id.year
            }
            state.eventCountData = action.payload.eventsCounts;
            state.loadingData = false;
        },
        [FetchEventCounts.rejected.toString()]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.loadingData = false;
        },
        [FetchCurrentDayEvents.pending.toString()]: (state) => {
            state.loadingData = true;
            state.status = 'loading';
        },
        [FetchCurrentDayEvents.fulfilled.toString()]: (state, action) => {
            state.status = 'succeeded';
            state.currentDayEvents = action.payload.events.sort((a:any,b:any) => {
                const date1 :any= new Date(a.startDate);
                const date2 :any= new Date(b.startDate);
                return date1 - date2;
            });
            state.eventsLastFetched = Date.now();
            state.loadingData = false;
        },
        [FetchCurrentDayEvents.rejected.toString()]: (state, action) => {
            state.status = 'failed';
            state.error = action.error.message;
            state.loadingData = false;
        },
    },
});

export const {resetData} = cacheReducer.actions;

export default cacheReducer.reducer;

import { createDraftSafeSelector, createSelector } from '@reduxjs/toolkit'
import {ICacheState} from "./cache.interface";

const selectDomain = (state: any) => state.cacheReducer;

export const getImagesData = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.imagesData);

export const getImagesPagination = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.imagesPagination);

export const getImagesLastFetched = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.imagesLastFetched);
export const getEventsCount = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.eventCountData);
export const getCurrentDayEvents = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.currentDayEvents);
export const getEventsLastFetched = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.eventsLastFetched);
export const getCachedLoadingState = createDraftSafeSelector(selectDomain, (user: ICacheState) => user.loadingData);

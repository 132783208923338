import moment from "moment";
import React, { useState, useEffect } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { images } from "../../assets/images"
import { AppLayout } from "../../components/layouts/AppLayout"
import { IIdFromUrl } from "../../interfaces/id-from-url.interface";
import { chatService } from "../../services/chat.service";
import { organisationService } from "../../services/organisation.service";
import { userService } from "../../services/user.service";
import { FullPageLoader } from "../../utils/FullPageLoader";
import { Toast } from "../../utils/Toast";
import { EPrivacy } from "../auth/pages/signup/interfaces/drop-down.enums";
import { EChatType } from "../chatmessages/enum/type.enum";
import { UpdateUserGroupModal } from "./components/UpdateUserGroupModal";
import {AssignUserFieldsModal} from "./components/AssignUserFieldsModal";
import {ValidTillModal} from "../ownorganisation/components/ValidTillModal";
import {useTranslation} from "react-i18next";
import {
    IOrganizationRole
} from "../organisations/createorganisation/interfaces/organization-requested-members.interface";
import Swal from "sweetalert2";
import {MemberRoleChangeModal} from "../ownorganisation/components/MemberRoleChangeModal";

export const MemberProfilePage = () => {
    const [isLoading, setIsloading] = useState(true);
    const [chatLoader, setChatLoader] = useState(false);
    const [showGroupModal, setShowGroupModal] = useState(false);
    const [showFieldsModal, setShowFieldsModal] = useState(false);
    const [showValidityModal, setShowValidityModal] = useState(false);
    const [showMemberRoleChangeModal, setShowMemberRoleChangeModal] = useState(false);
    const [organizationRoles, setOrganizationRoles] = useState<IOrganizationRole[]>([])
    const [orgRole, setOrgRole] = useState<any>();
    const [user, setUser] = useState<any>();
    const [sharedOrganizations, setSharedOrganization] = useState<any[]>();
    const [sharedGroups, setSharedGroups] = useState<any[]>();
    const [memberIds, setMemberIds] = useState<string[]>([]);
    let { id } = useParams<IIdFromUrl>();
    const history = useHistory();
    const location = useLocation();
    const [organizationId, setOrganizationId] = useState<string | null>(location?.search?.replace('?', '').split('&')[0]);
    const [memberId, setMemberId] = useState<string | null>(location?.search?.replace('?', '').split('&')[1]);
    const { t } = useTranslation('', { keyPrefix: 'MemberProfilePage' });

    useEffect(() => {
        getUserProfile(id);
        if (memberId)
        {
            getUserOrganizationComment(memberId)
        }
        if (organizationId){
            getOrganizationRoles(organizationId)
        }
        // getUserOrganizations()
    }, [])

    const getUserProfile = async (id: string) => {
        setIsloading(true)
        const res: any = await userService.getUserProfile(id);
        setIsloading(false)
        if (res.statusCode == 200) {
            setSharedOrganization(res.payload.organizations)
            const remp = [];
            for (const organization of res.payload.organizations) {
                remp.push(organization._id)
            }
            setMemberIds(remp)
            setUser(res.payload.user);
            setSharedGroups(res.payload.groups);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getUserOrganizations = async () => {
        setIsloading(true)
        const res: any = await organisationService.getUserOrganizations(id)
        setIsloading(false)
        if (res.statusCode === 200) {
            setSharedOrganization(res.payload.organizations)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const getUserOrganizationComment = async (sample_id: string) => {
        setIsloading(true)
        const res: any = await organisationService.getUserOrganizationComment(sample_id)
        setIsloading(false)
        if (res.statusCode === 200) {
            setOrgRole(res?.payload?.member)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const chatNowHandler = async (userId: string) => {
        try {
            setChatLoader(true);
            const chat = await chatService.checkIndividualChat(userId);
            history.push(`/all-chat?tab=${EChatType.ONE_TO_ONE}&active=${chat.payload.chat._id}`)
        } catch (err) {
            setChatLoader(false);
            Toast.fire({
                icon: 'warning',
                title: 'Something went wrong.'
            })
        }
    }

    const closeGroupModal = () => {
        setShowGroupModal(false);
    }
    const closeFieldsModal = () => {
        setShowFieldsModal(false);
    }

    const getGroupRole = (group: any) => {
        for (let i = 0; i < memberIds?.length; i++) {
            if (group.admins.includes(memberIds[i]))
            {
                return  'Admin'
            }
            if (group.members.includes(memberIds[i])){
                return  'Follower'
            }
        }
    }
    const validTillCloseModal = (data?: string) => {
        setShowValidityModal(false);
        if (data)
        {
            setOrgRole({...orgRole, validTill: moment(data)})
        }
    }
    const getOrganizationRoles = async (id: string) => {
        const res: any = await organisationService.getOrganizationRoles(id);
        if (res.statusCode == 200) {
            for (const iterator of res.payload.organizationRoles) {
                iterator.checked = false;
            }
            setOrganizationRoles(res.payload.organizationRoles)
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const removeMemberFromOrganization = async () => {
        Swal.fire({
            title: user?.name + " (" + user?.username + ")",
            text: t('areYouSurePublicMember'),
            imageUrl: user?.avatar ?? images.UserPlaceholder,
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'User Image',
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t('yes'),
            cancelButtonText: t('no'),
            customClass: {
                image: 'rounded-image'
            }
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    try {
                        setIsloading(true)
                        const res: any = await organisationService.makeOrganizationUserPublic(memberId!);
                        if (res.statusCode == 200) {
                            getUserProfile(id);
                            Toast.fire({
                                icon: 'success',
                                title: t('roleChangeSuccess')
                            })
                        } else if (res.statusCode == 400) {
                            setIsloading(false)
                            Toast.fire({
                                icon: 'warning',
                                title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                            })
                        } else {
                            setIsloading(false)
                            Toast.fire({
                                icon: 'warning',
                                title: res.message
                            })
                        }
                    } catch (e: any) {
                        setIsloading(false)
                        Toast.fire({
                            icon: 'warning',
                            title: e?.response?.data?.message
                        })
                    }
                } else {
                    Swal.fire({
                        title: t('removeMember') + user.name + " (" + user.username + ")",
                        text: t('areYouSureMember'),
                        imageUrl: user.avatar ?? images.UserPlaceholder,
                        imageWidth: 100,
                        imageHeight: 100,
                        imageAlt: 'User Image',
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: t('yes'),
                        cancelButtonText: t('no'),
                        customClass: {
                            image: 'rounded-image'
                        }
                    }).then(async (result: any) => {
                        if (result) {
                            if (result.isConfirmed) {
                                try {
                                    setIsloading(true)
                                    const res: any = await organisationService.removeOrganizationMembers(memberId!);
                                    setIsloading(false)
                                    if (res.statusCode == 200) {
                                        setOrganizationId(null)
                                        setMemberId(null)
                                        history.goBack();
                                        Toast.fire({
                                            icon: 'success',
                                            title: t('userRemovedSuccess')
                                        })
                                    } else if (res.statusCode == 400) {
                                        Toast.fire({
                                            icon: 'warning',
                                            title: t('publicMemberError') + res.payload.groups.map((item: any) => (" " + item.name)).toString()
                                        })
                                    } else {
                                        Toast.fire({
                                            icon: 'warning',
                                            title: res.message
                                        })
                                    }
                                } catch (e: any) {
                                    setIsloading(false)
                                    Toast.fire({
                                        icon: 'warning',
                                        title: e?.response?.data?.message
                                    })
                                }
                            }
                        }
                    });
                }
            }
        });
    }
    const closeMemberRoleChangeModal = (data: boolean) => {
        setShowMemberRoleChangeModal(false)
    }
    const updateMemberRole = (updatedRole: any, shouldClose?: boolean) => {
        if (!shouldClose)
        {
            setShowMemberRoleChangeModal(false);
        }
        setOrgRole({...orgRole});
    }

    return (
        <AppLayout>
            {/* <div>Member Profile</div> */}
            {isLoading && <FullPageLoader />}
            <div className="up-ownorganisationprofilepage up-myprofilepage">
                <div className="up-themebanner">
                    <figure className="up-bannerimg">
                        <img src={user?.banner ?? images.RestPlaceholder} alt="Profile Banner Image" />
                    </figure>
                </div>
                <div className="up-bannerbottomarea up-memberprofilebottomheight">
                    <div className="up-profilenameimage">
                        <figure className="up-organisationprofileimg">
                            <img src={user?.avatar ?? images.UserPlaceholder} alt="Organisation Profile Image" />
                        </figure>
                        <div className="up-organisationname">
                            <h3>{user?.name}</h3>
                            <span>{"@" + user?.username}</span>
                        </div>
                    </div>
                    <div className="up-memberprofilright up-memberprofilepaddinglow">
                        {/* <ul className="up-socialmedias">
                            {user?.socialLinks && user.socialLinks.map((link: string) => (
                                <>
                                    {link && <>
                                        {link.includes('facebook') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btnfacebook">
                                                <i className="icon-facebook"></i>
                                            </a>
                                        </li>}
                                        {link.includes('twitter') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btntwitter">
                                                <i className="icon-twitter"></i>
                                            </a>
                                        </li>}
                                        {link.includes('instagram') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btninstagram">
                                                <i className="icon-instagram"></i>
                                            </a>
                                        </li>}
                                        {link.includes('linkedin') && <li>
                                            <a href={link} target="_blank" className="up-btnsocialmedia up-btnlinkedin">
                                                <i className="icon-linkedin"></i>
                                            </a>
                                        </li>}
                                    </>}
                                </>
                            ))}




                        </ul> */}
                        {organizationId && <button type="button" title={t('removeUser')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => removeMemberFromOrganization()}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM471 143c9.4-9.4 24.6-9.4 33.9 0l47 47 47-47c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9l-47 47 47 47c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-47-47-47 47c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l47-47-47-47c-9.4-9.4-9.4-24.6 0-33.9z"/></svg>
                        {/* <span>{t('removeUser')}</span> */}
                        </button>}
                        {organizationId && <button type="button" title={t('changeRole')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => setShowMemberRoleChangeModal(true)}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M432 96a48 48 0 1 0 0-96 48 48 0 1 0 0 96zM347.7 200.5c1-.4 1.9-.8 2.9-1.2l-16.9 63.5c-5.6 21.1-.1 43.6 14.7 59.7l70.7 77.1 22 88.1c4.3 17.1 21.7 27.6 38.8 23.3s27.6-21.7 23.3-38.8l-23-92.1c-1.9-7.8-5.8-14.9-11.2-20.8l-49.5-54 19.3-65.5 9.6 23c4.4 10.6 12.5 19.3 22.8 24.5l26.7 13.3c15.8 7.9 35 1.5 42.9-14.3s1.5-35-14.3-42.9L505 232.7l-15.3-36.8C472.5 154.8 432.3 128 387.7 128c-22.8 0-45.3 4.8-66.1 14l-8 3.5c-32.9 14.6-58.1 42.4-69.4 76.5l-2.6 7.8c-5.6 16.8 3.5 34.9 20.2 40.5s34.9-3.5 40.5-20.2l2.6-7.8c5.7-17.1 18.3-30.9 34.7-38.2l8-3.5zm-30 135.1l-25 62.4-59.4 59.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L340.3 441c4.6-4.6 8.2-10.1 10.6-16.1l14.5-36.2-40.7-44.4c-2.5-2.7-4.8-5.6-7-8.6zM256 274.1c-7.7-4.4-17.4-1.8-21.9 5.9l-32 55.4L147.7 304c-15.3-8.8-34.9-3.6-43.7 11.7L40 426.6c-8.8 15.3-3.6 34.9 11.7 43.7l55.4 32c15.3 8.8 34.9 3.6 43.7-11.7l64-110.9c1.5-2.6 2.6-5.2 3.3-8L261.9 296c4.4-7.7 1.8-17.4-5.9-21.9z"/></svg> */}
                            <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 170.86 181">
                            {/* <defs>
                                <style>
                                .cls-1 {
                                    fill: '#fff'
                                }

                                .cls-2 {
                                    fill: none;
                                }
                                </style>
                            </defs> */}
                            <g id="Laag_1-2" data-name="Laag_1">
                                <path className="cls-1" style={{fill: '#fff'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                <path className="cls-1" style={{fill: '#fff'}} d="M138.4,123.84v6.48h-21.08v-6.48c0-5.82,4.72-10.54,10.54-10.54,2.91,0,5.55,1.18,7.45,3.09,1.91,1.9,3.09,4.54,3.09,7.45Z"/>
                                <path className="cls-1" style={{fill: '#fff'}} d="M127.86,94.94c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.26,43-43-19.25-43-43-43ZM149.71,161.32c0,3.16-2.56,5.72-5.71,5.72h-32.28c-3.15,0-5.71-2.56-5.71-5.72v-25.28c0-3.16,2.56-5.72,5.71-5.72h.94v-6.29c0-8.39,6.81-15.19,15.2-15.19,4.2,0,7.99,1.7,10.75,4.45,2.74,2.75,4.45,6.55,4.45,10.74v6.29h.94c3.15,0,5.71,2.56,5.71,5.72v25.28Z"/>
                                <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                <g>
                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                <path className="cls-2" style={{fill: 'none'}} d="M132.97,157.85c-.04.06-.13.14-.28.14h-9.65c-.16,0-.25-.09-.29-.14-.04-.06-.1-.17-.05-.32l.69-2.09,2.24-6.82-.54-.39c-1.51-1.04-2.28-2.68-2.11-4.45.01-.11.02-.23.04-.34.35-2.1,2.07-3.75,4.19-4.03.23-.03.45-.04.66-.04,1.4,0,2.41.6,3.04,1.15.97.83,1.64,2.05,1.81,3.26.03.17.04.34.04.52.04,1.44-.69,2.57-1.11,3.08-.11.12-.46.51-1,.89l-.55.38,2.23,6.79.69,2.09c.04.15-.01.27-.05.32Z"/>
                                </g>
                            </g>
                            </svg>
                        {/* <span>{t('changeRole')}</span> */}
                        </button>}
                        {organizationId && <button type="button" title={t('validity')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => setShowValidityModal(true)}>
                            {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M152 24c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L64 64C28.7 64 0 92.7 0 128l0 16 0 48L0 448c0 35.3 28.7 64 64 64l320 0c35.3 0 64-28.7 64-64l0-256 0-48 0-16c0-35.3-28.7-64-64-64l-40 0 0-40c0-13.3-10.7-24-24-24s-24 10.7-24 24l0 40L152 64l0-40zM48 192l352 0 0 256c0 8.8-7.2 16-16 16L64 464c-8.8 0-16-7.2-16-16l0-256z"/></svg>
                             */}
                             <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.77 181">
                                {/* <defs>
                                    <style>
                                    .cls-1 {
                                        fill: '#fff'
                                    }

                                    .cls-2 {
                                        fill: 'none'
                                    }
                                    </style>
                                </defs> */}
                                <g id="Laag_1-2" data-name="Laag_1">
                                    <path className="cls-1" style={{fill: '#fff'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                    <path className="cls-1" style={{fill: '#fff'}} d="M128.77,94.59c-23.75,0-43,19.25-43,43s19.25,43,43,43,43-19.25,43-43-19.25-43-43-43ZM153.24,159.53c0,3.07-2.49,5.56-5.56,5.56h-37.82c-3.07,0-5.56-2.49-5.56-5.56v-37.82c0-3.07,2.49-5.56,5.56-5.56h6.37c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.79-2.8.78,0,1.48.31,1.98.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h14.06c-.03-.15-.04-.3-.04-.46v-2.8c0-1.55,1.25-2.8,2.8-2.8.77,0,1.47.31,1.97.82.51.5.82,1.2.82,1.98v2.8c0,.16-.01.31-.04.46h6.37c3.07,0,5.56,2.49,5.56,5.56v37.82Z"/>
                                    <circle className="cls-1" style={{fill: '#fff'}} cx="84.62" cy="43" r="43"/>
                                    <path className="cls-1" style={{fill: '#fff'}} d="M102.11,181H17.99c-11.92,0-20.46-11.35-17.34-22.85,10.04-36.97,43.83-64.15,83.97-64.15,5.66,0,11.2.54,16.56,1.57-14.15,8.83-23.56,24.53-23.56,42.43s9.82,34.29,24.49,43Z"/>
                                    <path className="cls-2" style={{fill: 'none'}} d="M106.58,128.27v30.13c0,2.4,1.95,4.35,4.35,4.35h35.68c2.4,0,4.35-1.95,4.35-4.35v-30.13h-44.38ZM128.89,157.77c-1.81,0-3.27-1.46-3.27-3.26s1.46-3.26,3.27-3.26,3.26,1.46,3.26,3.26-1.46,3.26-3.26,3.26ZM133.52,136.21l-2.3,11.53c-.14.68-.74,1.17-1.43,1.17h-2.22c-.7,0-1.29-.49-1.43-1.17l-2.3-11.53c-.18-.9.51-1.74,1.43-1.74h6.82c.92,0,1.61.84,1.43,1.74Z"/>
                                </g>
                                </svg>
                        {/* <span>{t('validity')}</span> */}
                        </button>}
                        {organizationId && <button type="button" title={t('assignUserFields')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => setShowFieldsModal(true)}>
                        {/* <?xml version="1.0" encoding="UTF-8"?> */}
                            <svg id="Laag_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.59 110.67">
                                <defs>
                                {/* <style>
                                    .cls-1 {
                                    fill: '#fff';
                                    {"}"}
                                </style> */}
                                </defs>
                                <g id="Laag_1-2" data-name="Laag_1">
                                <g>
                                    <circle className="cls-1" style={{fill: '#fff'}} cx="148.52" cy="26.15" r="26.15"/>
                                    <path className="cls-1" style={{fill: '#fff'}} d="M187.43,110.67h-77.82c-8.19,0-14.04-7.94-11.61-15.76,6.71-21.52,26.79-37.15,50.52-37.15s43.81,15.62,50.52,37.15c2.44,7.82-3.42,15.76-11.61,15.76Z"/>
                                    <rect className="cls-1" style={{fill: '#fff'}} x=".11" y="15.81" width="101.32" height="17.74" rx="8.87" ry="8.87"/>
                                    <rect className="cls-1" style={{fill: '#fff'}} x="0" y="75.4" width="67.55" height="17.74" rx="8.87" ry="8.87"/>
                                    <rect className="cls-1" style={{fill: '#fff'}} x=".44" y="46.22" width="101" height="17.74" rx="8.87" ry="8.87"/>
                                </g>
                                </g>
                            </svg>
                        {/* <span>{t('assignUserFields')}</span> */}
                        </button>}
                        {organizationId && <button type="button" title={t('assignUserGroups')} className="up-btn up-btncircel" style={{ marginRight: '8px' }} onClick={() => setShowGroupModal(true)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M96 128a128 128 0 1 1 256 0A128 128 0 1 1 96 128zM0 482.3C0 383.8 79.8 304 178.3 304l91.4 0C368.2 304 448 383.8 448 482.3c0 16.4-13.3 29.7-29.7 29.7L29.7 512C13.3 512 0 498.7 0 482.3zM609.3 512l-137.8 0c5.4-9.4 8.6-20.3 8.6-32l0-8c0-60.7-27.1-115.2-69.8-151.8c2.4-.1 4.7-.2 7.1-.2l61.4 0C567.8 320 640 392.2 640 481.3c0 17-13.8 30.7-30.7 30.7zM432 256c-31 0-59-12.6-79.3-32.9C372.4 196.5 384 163.6 384 128c0-26.8-6.6-52.1-18.3-74.3C384.3 40.1 407.2 32 432 32c61.9 0 112 50.1 112 112s-50.1 112-112 112z"/></svg>
                        {/* <span>{t('assignUserGroups')}</span> */}
                        </button>}
                        {user?.userCanChat === EPrivacy.EVERY_ONE &&
                            <button type="button" className="up-btn up-btncircel" title={t('chatNow')} disabled={chatLoader} onClick={() => chatNowHandler(user._id)}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M160 368c26.5 0 48 21.5 48 48l0 16 72.5-54.4c8.3-6.2 18.4-9.6 28.8-9.6L448 368c8.8 0 16-7.2 16-16l0-288c0-8.8-7.2-16-16-16L64 48c-8.8 0-16 7.2-16 16l0 288c0 8.8 7.2 16 16 16l96 0zm48 124l-.2 .2-5.1 3.8-17.1 12.8c-4.8 3.6-11.3 4.2-16.8 1.5s-8.8-8.2-8.8-14.3l0-21.3 0-6.4 0-.3 0-4 0-48-48 0-48 0c-35.3 0-64-28.7-64-64L0 64C0 28.7 28.7 0 64 0L448 0c35.3 0 64 28.7 64 64l0 288c0 35.3-28.7 64-64 64l-138.7 0L208 492z"/></svg>
                            {/* <span>Chat Now</span> */}
                             {chatLoader && <div className="lds-dual-ring"></div>}</button>
                        }
                        {showMemberRoleChangeModal && <MemberRoleChangeModal closeModal={closeMemberRoleChangeModal} organizationRoles={organizationRoles} selectedMember={orgRole!} updateMemberRole={updateMemberRole} />}
                        {showGroupModal && <UpdateUserGroupModal memberName={user?.name} memberId={memberId} organizationId={organizationId} closeModal={closeGroupModal} />}
                        {showFieldsModal && <AssignUserFieldsModal orgComment={orgRole?.comment} memberName={user?.name} memberId={memberId} organizationId={organizationId} closeModal={closeFieldsModal} />}
                        {showValidityModal && <ValidTillModal member={orgRole} closeModal={validTillCloseModal} />}
                    </div>
                </div>
                <div className="up-twocolumns">
                <aside className="up-sidebar">
                        <div className="up-widget up-widgetaboutorganisation">
                            <div className="up-widgethead up-textalignleft">
                                <h3>{t('about')}</h3>
                            </div>
                            <div className="up-organisationcontent">
                                <div className="up-description">
                                    <p>{user?.bio ?? t('notAvailable')}</p>
                                </div>
                                <ul className="up-organisationinfo">
                                    <li>
                                        <span>{t('email')}</span>
                                        <span>{user?.emailPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : user?.email}</span>
                                    </li>
                                    <li>
                                        <span>{t('address')}</span>
                                        <span>{user?.addressPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : user?.address}</span>
                                    </li>
                                    <li>
                                        <span>{t('dob')}</span>
                                        <span>{user?.dobPrivacy === EPrivacy.ONLY_ME ? t('notAvailable') : (user?.dob ? moment(user?.dob).format("MMM Do YYYY") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('memberSince')}</span>
                                        <span>{(user?.createdAt ? moment(user?.createdAt).format("YYYY-MM-DD HH:mm") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('lastVisit')}</span>
                                        <span>{(user?.appActivityAt ? moment(user?.appActivityAt).format("YYYY-MM-DD HH:mm") : t('notAvailable'))}</span>
                                    </li>
                                    <li>
                                        <span>{t('social')}</span>
                                        <div className="up-socialicons">
                                            {user?.socialLinks && user.socialLinks.map((link: string) => (
                                                <>
                                                    {link && <>
                                                        {link.includes('facebook') && <a className="up-facebooksocial" href={link} target="_blank">
                                                            <i className="icon-facebook"></i>
                                                        </a>}
                                                        {link.includes('twitter') && <a className="up-twittersocial" href={link} target="_blank">
                                                            <i className="icon-twitter"></i>
                                                        </a>}
                                                        {link.includes('instagram') && <a className="up-instagramsocial" href={link} target="_blank">
                                                            <i className="icon-instagram"></i>
                                                        </a>}
                                                        {link.includes('linkedin') && <a className="up-linkedinsocial" href={link} target="_blank">
                                                            <i className="icon-linkedin"></i>
                                                        </a>}
                                                    </>}
                                                </>
                                            ))}
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </aside>
                    <div className="up-profilefollowingarea">
                        <div className="up-tablearea">
                            <h3>{t('sharedOrganisation')}</h3>
                            <table className="up-filestable table up-groupstable up-tableheading">
                                <thead>
                                    <tr>
                                        <th>{t('organisationName')}</th>
                                        <th>{t('userRole')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sharedOrganizations && sharedOrganizations.map(org => (
                                        <tr>
                                            <td>
                                                <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={org?.organization?.avatar ?? images.RestPlaceholder} alt="Organisation Icon" />
                                                    </span>
                                                    <h4><a href={`/organization-profile/${org?.organization?._id}`}>{org?.organization?.name} </a></h4>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{org.role.role}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    {sharedOrganizations && sharedOrganizations.length == 0 && <tr>
                                        <td>{t('noRecordFound')}</td><td></td> </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="up-profilefollowingarea">
                        <div className="up-tablearea">
                            <h3>{t('organisationGroups')}</h3>
                            <table className="up-filestable table up-groupstable up-tableheading">
                                <thead>
                                    <tr>
                                        <th>{t('organisationName')}</th>
                                        <th>{t('userGroup')}</th>
                                        <th>{t('userRole')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {sharedGroups && sharedGroups.map(group => (
                                        <tr>
                                            <td>
                                                <div className="up-fileiconname up-tableimagecenter">
                                                    <span className="up-fileicon up-bgproject">
                                                        <img src={group?.organization?.avatar ?? images.RestPlaceholder} alt="Organisation Icon" />
                                                    </span>
                                                    <h4><a href={`/organization-profile/${group?.organization?._id}`}>{group?.organization?.name} </a></h4>
                                                </div>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{group.name}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                            <td>
                                                <ul className="up-organisermetadata">
                                                    <li>
                                                        <h4>{getGroupRole(group)}</h4>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                    {sharedGroups && sharedGroups.length == 0 && <tr>
                                        <td>{t('noRecordFound')}</td> <td></td> <td></td> </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                   
                </div>
            </div>
        </AppLayout>
    )
}
import {useEffect, useState} from "react";
import {useParams} from "react-router";
import {IIdFromUrl} from "../../../interfaces/id-from-url.interface";
import {IPaginationInfo} from "../../../interfaces/pagination-info.interface";
import {eventService} from "../../../services/events.service";
import {Toast} from "../../../utils/Toast";
import {IEvent} from "../../organisations/createorganisation/interfaces/event.interface";
import {IEventTab} from "../../organisations/createorganisation/interfaces/events-tab.interface";
import {CreateEventModal} from "./CreateEventModal";
import {EventDetailModal} from "./EventDetailModal";
import {Event} from "./Event";
import {EPolicy} from "../../../interfaces/organization-policies.enums";
import Swal from 'sweetalert2'
import {EEventJoiningStatus} from "../../../interfaces/event-join-status.interface";
import {EventDisplayToModal} from "../../themecomponents/EventDisplayToModal";
import {ImportEventModal} from "./ImportEventModal";
import InfiniteScroll from "react-infinite-scroll-component";
import {FullPageLoader} from "../../../utils/FullPageLoader";
import {useTranslation} from "react-i18next";
import {images} from "../../../assets/images";


export const EventsTab: React.FC<IEventTab> = (props) => {

    const [showCreateEventModal, setCreateEventModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showDetailModal, setShowDetailModal] = useState(false);
    const [showImportEventModal, setShowImportEventModal] = useState(false);
    const [selectedEditEvent, setSelectedEditEvent] = useState<IEvent>();
    const [organizationEvents, setOrganizationEvents] = useState<IEvent[]>([])
    const [eventsPaginationInfo, setEventsPaginationInfo] = useState<IPaginationInfo>()
    const [showDisplayToModal, setShowDisplayToModal] = useState(false);
    const [isLoading, setIsloading] = useState(true);
    const { t } = useTranslation('', { keyPrefix: 'EventsTab' });

    let { id } = useParams<IIdFromUrl>();
    const format2 = "YYYY-MM-DD"

    useEffect(() => {
        if (props.memberId.isMember) {
            getEvents(1)
        }
    }, [])
    const getEvents = async (page: number) => {
        setIsloading(true)
        const res: any = await eventService.getOrganizationEvents(id, page);
        setIsloading(false)
        if (res.statusCode === 200) {
            for (const iterator of res.payload.events.records) {
                iterator.isExpanded = false;
            }
            if (page == 1) {
                setOrganizationEvents([...res.payload.events.records])
            } else {
                setOrganizationEvents([...organizationEvents!, ...res.payload.events.records])
            }
            setEventsPaginationInfo(res.payload.events.paginationInfo);
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }
    const closeEventModal = (data: any) => {
        setCreateEventModal(false)
        setIsEdit(false);
        setSelectedEditEvent(undefined)
    }
    const closeEventDetailModal = (data: any) => {
        setShowDetailModal(false)
    }
    const showDetail = async (id: string, index: number, show: boolean) => {
        if (show) {
            const res: any = await eventService.getEventDetail(id);
            let tempEvents: any = organizationEvents
            tempEvents[index] = res.payload.event;
            tempEvents[index].isExpanded = show;
            setOrganizationEvents([...tempEvents])
        } else {
            const tempEvents: any = organizationEvents
            tempEvents[index].isExpanded = show;
            setOrganizationEvents([...tempEvents])
        }
    }
    const updateJoiningStatus = async (event: IEvent, status: string, index: number) => {
        const data = {
            memberId: props.memberId._id,
            joiningStatus: status
        }
        const res: any = await eventService.joinEvent(event._id, data)
        if (res.statusCode == 200) {
            const tempEvents: any = organizationEvents
            tempEvents[index].joiningStatus = status;
            switch (status) {
                case EEventJoiningStatus.JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: res.message
                    })
                    break;
                case EEventJoiningStatus.INTERESTED:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventUpdated')
                    })
                    break;
                case EEventJoiningStatus.NOT_JOINING:
                    Toast.fire({
                        icon: 'success',
                        title: t('eventUpdated')
                    })
                    break;
            }
            setOrganizationEvents([...tempEvents])
        } else {
            Toast.fire({
                icon: 'warning',
                title: res.message
            })
        }
    }

    const eventCreated = (event: IEvent, created: boolean) => {
        if (created) {
            if (organizationEvents) {
                setOrganizationEvents([event,...organizationEvents!])
            } else {
                setOrganizationEvents([event])
            }
        } else {
            const tempIndex = organizationEvents!.findIndex(eve => eve._id == event._id);
            organizationEvents!.splice(tempIndex, 1, event)
            setOrganizationEvents([...organizationEvents!])
        }
    }
    const editEvent = (event: IEvent, index: number) => {
        setSelectedEditEvent(event);
        setIsEdit(true);
        setCreateEventModal(true)
    }
    const deleteEvent = async (event: IEvent, index: number) => {
        Swal.fire({
            title: t('deleteEvent') + event.name,
            text: t('areYouSure'),
            imageUrl: event?.avatar ?? images.RestPlaceholder,
            imageWidth: 100,
            imageHeight: 100,
            imageAlt: 'Event Image',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t('delete'),
            cancelButtonText: t('cancel'),
            customClass: {
                image: 'rounded-image'
            }
        }).then(async (result: any) => {
            if (result) {
                if (result.isConfirmed) {
                    const res: any = await eventService.deleteEvent(event._id);
                    if (res.statusCode === 200) {
                        Toast.fire({
                            icon: 'success',
                            title: res.message
                        })
                        organizationEvents!.splice(index, 1)
                        setOrganizationEvents([...organizationEvents!])
                    } else {
                        Toast.fire({
                            icon: 'warning',
                            title: res.message
                        })
                    }
                }
            }
        })
    }

    const closeEventDisplayToModal = (data: any) => {
        setShowDisplayToModal(false)
    }

    const openDisplayToModal = (event: IEvent) => {
        if (event.displayTo.length > 0) {
            setSelectedEditEvent(event);
            setShowDisplayToModal(true)
        }
    }
    const closeCSVModal = (data: boolean) => {
        setShowImportEventModal(false)
        if (data) {
            getEvents(1);
        }
    };
    return <>  <div className="up-eventstabsarea">
        <div className="up-themeboxhead">
            <h3>{t('events')}</h3>
            {isLoading && <FullPageLoader />}
            {props.memberId.role.policies.includes(EPolicy.CAN_ADD_EVENTS) && <button className="up-btn" type="button" onClick={() => setCreateEventModal(true)}>{t('createNew')}</button>}
            {props.memberId.role.policies.includes(EPolicy.CAN_ADD_EVENTS) && <button className="up-btn" type="button" style={{ marginRight: '8px' }} onClick={() => setShowImportEventModal(true)}>{t('importEvents')}</button>}
        </div>
        <div className="up-themebox">
            <div className="up-alleventstwocols">
                <div className="up-eventsholder">
                    <div className="up-alleventsholder">
                        <h5>{t('upcoming')}</h5>
                        <InfiniteScroll
                            dataLength={organizationEvents!.length} //This is important field to render the next data
                            next={() => getEvents(eventsPaginationInfo?.currentPage! + 1)}
                            hasMore={organizationEvents!.length !== eventsPaginationInfo?.totalRecords!}
                            loader={<h4>{t('loading')}</h4>}
                            endMessage={
                                <p className="up-allpostseen" style={{ textAlign: 'center' }}>
                                    <b>{t('noMoreEvents')}</b>
                                </p>
                            }>
                            {organizationEvents && organizationEvents!.map((event: IEvent, index: number) => <Event fromAdminDashboard={false} fromTab={true} index={index} event={event} memberId={props.memberId}
                                key={event._id} deleteEvent={deleteEvent} editEvent={editEvent} setSelectedEditEvent={setSelectedEditEvent} updateJoiningStatus={updateJoiningStatus}
                                showDetail={showDetail} setShowDetailModal={setShowDetailModal} openDisplayToModal={openDisplayToModal} />)}
                        </InfiniteScroll>

                    </div>
                </div>
            </div>
        </div>
    </div>
        {showCreateEventModal && <CreateEventModal isEdit={isEdit} editEvent={selectedEditEvent!} organizationRoles={props?.organizationRoles} closeModal={closeEventModal} eventCreated={eventCreated} />}
        {showDetailModal && <EventDetailModal editEvent={selectedEditEvent!} closeModal={closeEventDetailModal} />}
        {showImportEventModal && <ImportEventModal closeModal={closeCSVModal} />}
        {showDisplayToModal && <EventDisplayToModal editEvent={selectedEditEvent!} closeModal={closeEventDisplayToModal} />}
    </>

}